<script>
// Extensions
import View from "@/views/View";

// Mixins
import LoadSections from "@/mixins/load-sections";

export default {
  name: "Mekanisme",

  metaInfo: { title: "Mekanisme Penjaminan" },

  extends: View,

  mixins: [LoadSections(["hero", "mechanism", "info"])],

  props: {
    id: {
      type: String,
      default: "mekanisme-penjaminan",
    },
  },
};
</script>
